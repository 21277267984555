<template>
  <div class="formbox">
    <el-form
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="120px"
      ref="ruleForm"
      status-icon
    >
      <div class="title">商家类型</div>
      <el-form-item label="商家类型" prop="merchantType">
        <el-radio label="ENTERPRISE" v-model="ruleForm.merchantType" disabled>企业</el-radio>
        <el-radio label="PERSONAL" v-model="ruleForm.merchantType" disabled>个人</el-radio>
      </el-form-item>
      <el-form-item label="采货方式" prop="purchaseType" v-if="ruleForm.merchantType==='ENTERPRISE' || supplementStatus==='NO'">
        <el-radio label="DROP_SHIPPING" v-model="ruleForm.purchaseType">一件代发</el-radio>
        <el-radio label="PURCHASE" v-model="ruleForm.purchaseType">采销</el-radio>
      </el-form-item>
      <el-form-item label="店铺月销量" prop="monthlySales" v-if="ruleForm.merchantType==='ENTERPRISE'  || supplementStatus==='NO'">
        <el-select v-model="ruleForm.monthlySales" placeholder="请选择店铺月销量">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in ruleForm.purchaseType === 'DROP_SHIPPING'
              ? monthlySalesDropOptions
              : monthlySalesPurchaseOptions"
            :key="item.value"
            purchaseType
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="title">店铺信息</div>
      <el-form-item label="店铺名称" prop="shopName">
        <el-input
          class="input"
          placeholder="运营店铺的名称"
          v-model.trim="ruleForm.shopName"
          :disabled="ruleForm.merchantType === 'PERSONAL' && supplementStatus==='NO'"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司名称" prop="company" v-if="ruleForm.merchantType === 'ENTERPRISE'">
        <el-input
          class="input"
          placeholder="营业执照上的中文全称"
          v-model.trim="ruleForm.company"
        ></el-input>
      </el-form-item>
      <div class="title">{{ ruleForm.merchantType === 'ENTERPRISE'?'联系人信息':'买家实名信息' }}</div>
      <el-form-item label="真实姓名" prop="contactName">
        <el-input
          class="input"
          placeholder="联系人的真实姓名"
          v-model.trim="ruleForm.contactName"
          :disabled="ruleForm.merchantType === 'PERSONAL' && supplementStatus==='NO'"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="contactPhone" v-if="ruleForm.merchantType === 'ENTERPRISE' || supplementStatus==='NO'">
        <el-input
          class="input"
          placeholder="联系人的手机号"
          v-model.trim="ruleForm.contactPhone"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idcardNo" v-if="ruleForm.merchantType === 'PERSONAL' && supplementStatus!=='NO'">
        <el-input
            class="input"
            placeholder="联系人的身份证号码"
            v-model.trim="ruleForm.idcardNo"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email" v-if="ruleForm.merchantType === 'ENTERPRISE' || supplementStatus==='NO'">
        <el-input
          class="input"
          placeholder="联系人的邮箱，用于发放素材"
          v-model.trim="ruleForm.email"
        ></el-input>
      </el-form-item>
      <div class="title enclosure" v-if="ruleForm.merchantType==='ENTERPRISE' || supplementStatus==='NO'">
        附件({{`${ruleForm.merchantType === 'ENTERPRISE' ? '营业执照' : '身份证'}` }}或店铺后台截图)
      </div>
      <el-form-item label prop="additionalInfoJson" v-if="ruleForm.merchantType === 'ENTERPRISE' || supplementStatus==='NO'">
        <PictureCard
          :limit="9"
          :maxSize="2000 * 1024"
          v-model="ruleForm.additionalInfoJson"
        ></PictureCard>
        <div class="tip">最多上传9张,最大2m</div>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitForm('ruleForm')" type="primary">提交审核</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDicListByType } from '@/api/step';
import PictureCard from '@/components/Upload/PictureCard';
import { validatePhoneLoose, validateEmail } from '@/utils/validate';
import { throttleAt } from '@/utils/enhance';
import { validateIdCard } from '@/common/validator';
export default {
  name: 'StepForm',
  components: { PictureCard },
  props: {
    onlineList: Array,
    merchantType: String,
    supplementStatus: String,
  },

  data() {
    return {
      rules: {
        monthlySales: [{ required: true, message: '请选择店铺月销量', trigger: 'change' }],
        shopName: [
          {
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur',
          },
        ],
        company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhone: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePhoneLoose,
          },
        ],
        email: [{ required: true, trigger: 'blur', validator: validateEmail }],
        additionalInfoJson: [{ required: true, message: '请上传图片', trigger: 'change' }],
        idcardNo: [{ required: true, message: '请填写身份证号', trigger: 'blur' }, { validator: validateIdCard, trigger: 'blur' }],
      },
      ruleForm: {
        merchantType: 'PERSONAL', // 商家类型 默认个人  PERSONAL 个人, ENTERPRISE 企业 ,
        purchaseType: 'DROP_SHIPPING',
        monthlySales: '',
        shopName: '', // 店铺名称
        company: '', // 公司名称
        contactName: '', // 真实姓名
        contactPhone: '', // 手机号
        email: '', // 邮箱
        additionalInfoJson: [], // 附件图片
        idcardNo: '', // 身份证号
      },
      monthlySalesPurchaseOptions: [],
      monthlySalesDropOptions: [],
    };
  },
  created() {
    this.getDicListByType();
  },
  computed: {
    data() {
      const ruleForm = { ...this.ruleForm };
      const data = {
        ...ruleForm,
        additionalInfoJson: ruleForm.additionalInfoJson.map((item) => item.url),
      };
      if (ruleForm.merchantType === 'PERSONAL') {
        delete data.company;
      }
      return data;
    },
  },
  watch: {
    'ruleForm.purchaseType'() {
      this.ruleForm.monthlySales = '';
    },
    merchantType(val) {
      if (val) {
        this.ruleForm.merchantType = val;
      }
    },
  },
  methods: {
    getDicListByType() {
      // 获取经营渠道
      const q1 = getDicListByType('soyoungzg_monthly_sales_quantity_type'); // 店铺月销量,一件代发
      const q2 = getDicListByType('soyoungzg_monthly_sales_amount_type'); // 店铺月销量,采销
      Promise.all([q1, q2]).then(([q1, q2]) => {
        this.monthlySalesDropOptions = q1.data || [];
        this.monthlySalesPurchaseOptions = q2.data || [];
      });
    },
    setData(data) {
      const {
        merchantType = '',
        purchaseType = '',
        monthlySales = '',
        contactPhone,
        contactName,
        email,
        company,
        shopName,
        additionalInfoJson = [],
        idcardNo = '',
      } = data;
      this.ruleForm = {
        merchantType, // 商家类型
        purchaseType, // 采货方式
        monthlySales: '', // 店铺月销量
        shopName, // 店铺名称
        company, // 公司名称
        contactName, // 联系人
        contactPhone, // 联系方式
        idcardNo, // 身份证号
        email, // 邮箱
        additionalInfoJson: additionalInfoJson.map((item) => ({
          url: item,
        })), // 附件图片
      };
      setTimeout(() => {
        this.ruleForm.monthlySales = monthlySales;
      }, 100);
    },
    submitForm(formName) {
      throttleAt(async () => {
        await this.$refs[formName].validate((valid) => {
          if (valid) {
            const emitData = JSON.parse(JSON.stringify(this.data));
            this.$emit('emitFormData', emitData);
          } else {
            return false;
          }
        });
      }, 5000)();
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      Object.assign(this.ruleForm, {
        monthlySales: '',
        company: '',
        contactPhone: '',
        email: '',
        idcardNo: '',
        additionalInfoJson: []
      });
      if(this.ruleForm.merchantType !== 'PERSONAL' || this.supplementStatus !== 'NO') {
        this.ruleForm.shopName = '';
        this.ruleForm.contactName = '';
      }
    },
  },
};
</script>

<style lang='scss'  scoped>
.tip {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
}
.input,
.el-select {
  width: 350px;
}
.formbox {
  position: relative;
}
.serviceIcon-box {
  text-align: center;
  img {
    width: 200px;
    height: 200px;
  }
  p {
    text-align: center;
    margin: 0;
    line-height: 1.6;
  }
}
.butcf {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.title {
  font-size: 16px;
  font-weight: bold;
  width: 130px;
  text-align: right;
  padding-right: 12px;
  margin-bottom: 20px;
}
.enclosure {
  width: 240px;
}
</style>
